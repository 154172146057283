@import 'src/styles/functions/_layout.scss';

.root {
  margin-bottom: spacing(larger);
}

.mainLabel {
  font-size: fontSize(smaller);
  line-height: 19px;
  margin-bottom: 7px;
}

.inputContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.inputContent {
  flex-basis: 30%;
  max-width: 30%;

  > div:first-child {
    margin-bottom: 0;
  }
}

.inputLabel {
  margin-top: 5px;
  font-size: fontSize(small);
  font-weight: bold;
  line-height: 16px;
}

.errorLabel {
  font-size: fontSize(small);
  margin-top: 5px;
  color: #fd5252;
}
