@import 'src/styles/functions/_layout.scss';
@import 'src/styles/mixins/_button.scss';
@import 'src/styles/_global.scss';

.root {
  width: 100%;
  position: relative;
}

.dropZoneContainer {
  border: 2px solid #eeeeee;
  background-color: #fafafa;
  height: 120px;
  display: flex;
  align-items: center;
  border-style: dashed;
  justify-content: center;
}

.hidden {
  display: none;
}

.imagePreviewRoot {
  position: relative;
  margin-top: 34px;
  text-align: center;
}

.imagePreviewContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow-x: auto;
  padding: 10px 0 0;
  max-width: calc(100vw - 630px);
}

.imagePreviewContent {
  min-width: 152px;
  border: 1px solid #ced4da;
  padding: 5px;
  position: relative;
  margin: 0px 6px 25px 6px;
}

.imagePreviewCloseButton {
  @include clear-button;
  background-color: #000;
  height: 19px;
  width: 19px;
  border-radius: 50%;
  position: absolute;
  color: $white;
  top: -10px;
  right: -10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imagePreview {
  width: 100%;
  padding-bottom: 104px;
  background-position: center;
  background-size: cover;
}

.dragAndDropLabel {
  color: $text-primary;
  font-size: fontSize(standard);
  text-align: center;
}

.dragAndDropBrowseFileLabel {
  color: $primary;
  cursor: pointer;
}

.headerContainer {
  margin-top: spacing(standard);
  margin-bottom: spacing(standard);
}

.header {
  font-size: fontSize(standard);
}

.headerCounter {
  font-weight: bold;
}

.subHeader {
  font-size: fontSize(smaller);
  line-height: 19px;
  margin-top: 14px;
}
