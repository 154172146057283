@import 'src/styles/mixins/_button.scss';
@import 'src/styles/_global.scss';

.root {
  @include base-button($primary);
}

.disabled {
  opacity: 0.7;
}

.icon {
  margin-right: 7px;
}
