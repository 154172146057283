@import 'src/styles/functions/_layout.scss';

.root {
  width: 100%;
  position: relative;
}

.formColumn {
  display: flex;
  justify-content: space-between;
}

.formRow {
  max-width: 32%;
  flex-basis: 32%;
}

.divider {
  box-sizing: border-box;
  height: 1px;
  width: 100%;
  border: 1px solid #000000;
  opacity: 0.1;
  margin: spacing(large) 0;
}

.checkboxWrapper {
  display: flex;
}
