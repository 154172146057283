// COLORS
$primary: #2096f3;
$text-primary: #203a45;
$text-secondary: #495057;
$light-gray: #6c757d;
$light-gray-1: #6c7a89;
$lighter-gray: #dee2e6;
$disabled-gray: #ddd;
$white: #fff;
$secondary-bg: #2b3445;
$dark-gray: rgba(255, 255, 255, 0.3);
$dark-gray-1: #343a40;
$dark-navy: #212529;
$divider: rgba(0, 0, 0, 0.1);
$error: #e53934;

$row-selected: #f3f3f3;
$row-hover: #f3f3f3;
