@import 'src/styles/_global.scss';

*,
*::before,
*::after {
  box-sizing: border-box;
}
body,
html,
#root {
  min-height: 100vh;
  margin: 0;
  font-family: 'Nunito Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-size: 1rem;
  font-weight: 400;
  color: $text-primary;
  text-align: left;
  display: block;
}

div {
  display: block;
}

li {
  text-align: -webkit-match-parent;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}
.custom-control {
  position: relative;
  display: block;
  min-height: 1.3125rem;
  padding-left: 1.5rem;
}

input[type='radio'],
input[type='checkbox'] {
  box-sizing: border-box;
  padding: 0;
}

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
button,
input {
  overflow: visible;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
