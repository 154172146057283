@import 'src/styles/_global.scss';

.root {
  -webkit-box-align: stretch;
  align-items: stretch;
  display: -webkit-box;
  display: flex;
  width: 100%;
  min-height: 100%;
  position: relative;
  &::before {
    width: 100%;
    height: 300px;
    position: absolute;
    top: 0;
    left: 0;
    background-color: $secondary-bg;
    content: ' ';
    z-index: -1;
  }
}
