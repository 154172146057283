@import 'src/styles/functions/_layout.scss';
@import 'src/styles/mixins/_link.scss';

.root {
  background-color: #f2f2f2;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  min-height: 100vh;
}

.headerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: spacing(larger) 25px;
  height: 64px;
  width: 100%;
}

.logoContainer {
  display: flex;
  align-items: center;
}

.logo {
  height: 25px;
  width: auto;
}

.logoLineBreaker {
  box-sizing: border-box;
  height: 30px;
  border: 1px solid #6c7a89;
  opacity: 0.5;
  margin-left: spacing(standard);
  margin-right: spacing(standard);
}

.logoTitle {
  color: #6c7a89;
  font-size: fontSize(standard);
  line-height: 22px;
  margin-top: 2px;
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.headerLink {
  text-decoration: none;
}
