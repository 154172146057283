@import 'src/styles/functions/_layout.scss';
@import 'src/styles/_global.scss';

.root {
  display: flex;
  align-items: center;
}

.primaryLabel {
  color: $text-primary;
  font-size: fontSize(smaller);
  line-height: 19px;
  margin-left: 8px;
  margin-right: 8px;
}

.switchContainer {
  display: inline-flex;
  align-items: center;
}

:global {
  .switch {
    opacity: 0;
    position: absolute;
    z-index: 1;
    width: 36px;
    height: 18px;
    cursor: pointer;
  }
  .switch + .label {
    position: relative;
    display: inline-block;
    margin: 0 4px;
  }
  .switch + .label::before {
    border-radius: 12px;
    border: 1px solid #ccc;
    text-align: left;
    float: left;
  }
  .switch.switch-bootstrap + .label::before {
    content: '';
    box-shadow: none;
    border: #adb5bd solid 1px;
    background-color: $white;
    width: 28px;
    height: 16px;
    -webkit-transition: background 0.1s ease;
    -o-transition: background 0.1s ease;
    transition: background 0.1s ease;
  }
  .switch.switch-bootstrap + .label::after {
    content: '';
    position: absolute;
    top: 2px;
    left: 3px;
    border-radius: 12px;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    width: 12px;
    height: 12px;
    text-align: center;
    background-color: #adb5bd;
    border: 4px solid #adb5bd;
    -webkit-transition: left 0.2s ease;
    -o-transition: left 0.2s ease;
    transition: left 0.2s ease;
  }
  .label-switch {
    line-height: 10px;
  }
  .switch-primary > .switch.switch-bootstrap:checked + .label::before {
    background-color: $primary;
    border-width: 0;
  }

  .switch.switch-bootstrap:checked + .label::after {
    left: 13px;
    background-color: $white;
    border: 4px solid $white;
    text-shadow: 0 -1px 0 rgba(0, 200, 0, 0.25);
  }

  .switch:disabled {
    cursor: auto;
  }

  .switch-primary > .switch.switch-bootstrap:disabled + .label::before {
    background-color: #e9ecef;
    border-color: #adb5bd;
    border-width: 1px;
  }

  .switch-primary > .switch.switch-bootstrap:disabled + .label::after {
    background-color: #adb5bd;
    border-color: #adb5bd;
  }

  .switch-primary > .switch.switch-bootstrap:disabled + .label::after {
    background-color: #adb5bd;
    border-color: #adb5bd;
  }
}
