@import 'src/styles/_global.scss';
@import 'src/styles/functions/_layout.scss';

.root {
  background: none;
  color: $white;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  padding-left: 10px;
  padding-right: 10px;
  width: auto;
  border-radius: 3.2px;
  &:focus {
    outline: none !important;
  }
  &.medium {
    font-size: fontSize(smaller);
    font-weight: bold;
    line-height: 21px;
    height: 31px;
    min-width: 73.4px;
  }
  &.small {
    padding: 0.15rem 0.5rem;
    font-size: 0.75rem;
    line-height: 1.5;
    border-radius: 0.1rem;
  }
  &.large {
    height: 40px;
    font-size: fontSize(standard);
    line-height: 1.5;
    border-radius: 0.3rem;
  }
  &.primary {
    color: $white;
    background-color: #2196f3;
    border-color: #2196f3;
  }
  &.secondary {
    color: $white;
    background-color: #9c27b0;
    border-color: #9c27b0;
  }
  &.success {
    color: $white;
    background-color: #4caf50;
    border-color: #4caf50;
  }
  &.danger {
    color: $white;
    background-color: #e53935;
    border-color: #e53935;
  }
  &.warning {
    color: $white;
    background-color: #fb8c00;
    border-color: #fb8c00;
  }

  &.info {
    color: $white;
    background-color: #009688;
    border-color: #009688;
  }

  &.normal {
    border-radius: 3.2px;
  }

  &.rounded {
    border-radius: 10rem;
  }

  &.primaryOutline {
    background-color: transparent;
    color: #2196f3;
    border: 1px solid #2196f3;
    &:hover {
      color: $white;
      background-color: #2196f3;
    }
  }
  &.secondaryOutline {
    background-color: transparent;
    color: #9c27b0;
    border: 1px solid #9c27b0;
    &:hover {
      color: $white;
      background-color: #9c27b0;
    }
  }
  &.successOutline {
    background-color: transparent;
    color: #4caf50;
    border: 1px solid #4caf50;
    &:hover {
      color: $white;
      background-color: #4caf50;
    }
  }
  &.dangerOutline {
    background-color: transparent;
    color: #e53935;
    border: 1px solid #e53935;
    &:hover {
      color: $white;
      background-color: #e53935;
    }
  }
  &.warningOutline {
    background-color: transparent;
    color: #fb8c00;
    border: 1px solid #fb8c00;
    &:hover {
      color: $white;
      background-color: #fb8c00;
    }
  }

  &.infoOutline {
    background-color: transparent;
    color: #009688;
    border: 1px solid #009688;
    &:hover {
      color: $white;
      background-color: #009688;
    }
  }
}
.icon {
  margin-right: 7px;
}
