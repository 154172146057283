@import 'src/styles/_global.scss';
@import 'src/styles/functions/_layout.scss';

.root {
}

.rootDisabled {
  background-color: $disabled-gray;
  opacity: 0.5;
}

.paddingContainer {
  position: relative;
  padding: 30px 35px;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
  box-sizing: border-box;
  max-height: 60px;
  padding: 19px;
}

.headerContainerNoChildren {
  border-bottom: none;
}

.headerText {
  font-size: fontSize(standard);
  line-height: 22px;
  font-weight: 600;
}

.icon {
  font-size: fontSize(bigger);
  line-height: 24px;
  color: $text-primary;
}

.content {
}

.contentCollapsed {
  display: none;
}
