@import 'src/styles/_global.scss';
@import 'src/styles/functions/_layout.scss';
@import 'src/styles/mixins/_button.scss';

.root {
  display: flex;
  color: $white;
  border-radius: 3px;
  font-size: fontSize(smaller);
}

.rootSuccess {
  background: #4caf4f;
}

.rootWraning {
  background: #e9b334;
}

.rootError {
  background: $error;
}

.checkContainer {
  background-color: rgba(255, 255, 255, 0.1);
  padding: 19px 16px 19px 18px;
}

.messageContainer {
  display: flex;
  flex: 1;
  padding-left: spacing(standard);
  align-items: center;
  font-weight: 600;
  line-height: 19px;
}

.closeContainer {
  display: flex;
  align-items: center;
  font-size: fontSize(standard);
  line-height: 16px;
  opacity: 0.5;
  padding-right: spacing(standard);
}

.closeButton {
  @include clear-button;
  cursor: pointer;
}
