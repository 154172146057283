@import 'src/styles/functions/_layout.scss';
@import 'src/styles/mixins/_button.scss';
@import 'src/styles/_global.scss';
@import 'src/styles/mixins/_link.scss';

.root {
  width: 100%;
  position: sticky;
  top: 0;
}

.card {
  position: relative;
}

.cardContainer {
  padding: spacing(standard);
}

.columnLeft {
  display: flex;
  flex-flow: column;
}

.secondaryButtonContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: spacing(standard);
}

.secondaryButton {
  @include outline-button($primary);
  height: 31px;
  border-radius: 3px;
  font-size: fontSize(smaller);
  line-height: 19px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.buttonTopSpacing {
  margin-top: 5px;
}

.linkButton {
  @include base-link($primary);
  text-decoration: none;
  display: flex;
  align-items: center;
}

.status {
  font-size: fontSize(smaller);
  line-height: 29px;
}

.statusValue {
  font-weight: bold;
}

.divider {
  border: 1px solid #000000;
  height: 1px;
  opacity: 0.1;
  width: 100%;
  margin-top: 14.5px;
  margin-bottom: 14.5px;
}

.moveToTrashButton {
  @include clear-button;
  height: 40px;
  color: #e53934;
  font-size: fontSize(smaller);
  line-height: 29px;
  display: flex;
  align-items: center;
}

.primaryButtonContainer {
  display: flex;
  justify-content: space-between;
}

.moveToTrashIcon {
  width: 13px;
  height: 29px;
  margin-right: 5px;
}

.publishButton {
  @include base-button($primary);
  width: auto;
  min-width: 100px;
}

.legend {
  font-weight: bold;
  line-height: 19px;
  font-size: fontSize(smaller);
}

.changeStatusOption {
  width: 149px;
  border: 1px solid $primary;
  color: $primary;
  font-size: fontSize(smaller);
}

.previewPublishedBtn {
  min-height: 38px;
}

.noMargin {
  margin: 0;
}
