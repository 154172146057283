.messageContainer {
  margin-top: 8px;
  margin-bottom: 25px;
}

.dash {
  list-style: none;
  margin-left: 0;
  padding-left: 1em;
  > li:before {
    display: inline-block;
    content: '-';
    width: 1em;
    margin-left: -1em;
  }
}
