@import 'src/styles/functions/_layout.scss';
@import 'src/styles/mixins/_button.scss';
@import 'src/styles/mixins/_card.scss';

.content {
  margin-top: spacing(big);
  display: flex;
}

.rightColumn {
  display: flex;
  flex-flow: column nowrap;
}

.cardRoot {
  @include base-card;
  flex: 1;
  padding: spacing(larger) spacing(larger) spacing(large) spacing(larger);
}

.hidden {
  display: none;
}

.submitButton {
  @include base-button($primary);
  margin-top: spacing(large);
}

.messageContainer {
  margin-bottom: 8px;
}

.rootContent {
  position: relative;
}
