@import 'src/styles/functions/_layout.scss';

.card {
  margin-top: spacing(big);
  padding: spacing(larger) 0 0 spacing(larger);
  position: relative;
}

.cardNotification {
  margin-top: 25px;
}

.cardBody {
  padding-bottom: 0;
}

.title {
  font-size: fontSize(bigger);
  font-weight: 600;
  line-height: 32px;
}

.container {
  display: flex;
  margin-top: spacing(large);
  position: relative;
  min-height: 350px;
}

.descriptionContainer {
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  padding-right: 18px;
  box-sizing: border-box;
  font-size: fontSize(smaller);
  max-width: 251px;
}

.content {
  flex: 1;
  padding-bottom: spacing(large);
}
