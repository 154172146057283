.root {
  width: 100%;
  min-height: 1067px;
}

.addInfoManually {
  color: #2096f3;
  font-size: 14px;
  padding: 15px;
  margin: 0;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}
