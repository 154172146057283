@import 'src/styles/functions/_layout.scss';
@import 'src/styles/mixins/_button.scss';
@import 'src/styles/_global.scss';

.root {
}

.wrapper {
  position: absolute;
  width: 100%;
  top: 77px;
  left: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrapperInner {
  padding: spacing(big);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}

.wrapperMessage {
  color: $text-primary;
  font-size: 40px;
  font-weight: bold;
  letter-spacing: -2px;
  line-height: 72px;
}
.resetBtn {
  @include base-button($primary);
  height: 37px;
  width: 152px;
  font-size: fontSize(standard);
  line-height: 22px;
  padding: 0;
}
