@import 'src/styles/_global.scss';
@import 'src/styles/functions/_layout.scss';
@import 'src/styles/mixins/_button.scss';

.root {
  display: flex;
  align-items: center;
}

.rootClickable {
  cursor: pointer;
  &:hover {
    background: #ececec;
  }
}

.headerLabel {
  color: $text-primary;
  font-size: fontSize(smaller);
  line-height: 19px;
  -ms-text-overflow: ellipsis;
  text-overflow: ellipsis;
  font-weight: 600;
}

.headerSortIconUp {
  font-size: 11px;
  color: #bdc4c7;
}

.headerSortIconDown {
  font-size: 11px;
  color: #bdc4c7;
}

.headerSortButton {
  @include clear-button;
  margin-left: 6px;
}
