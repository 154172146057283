@import 'src/styles/functions/_layout.scss';
@import 'src/styles/_global.scss';

.root {
  border-radius: 5px;
  border: 1px solid #ced4da;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &.error {
    border: 1px solid #e53934;
  }
  &:hover {
    border: 1px solid #2196f3;
  }
}

.select {
  > div[class$='control'] {
    min-height: 36px !important;
    max-height: 36px !important;
  }
}

.inputAdournment {
  height: 36px;
  width: 36px;
  background-color: #e9ecef;
  display: flex;
  align-items: center;
  justify-content: center;
}

.indicatorSeparator {
  display: none;
}

.label {
  display: inline-block;
  margin-bottom: 0.5rem;
  box-sizing: border-box;
  cursor: default;
  color: $text-primary;
  font-size: fontSize(smaller);
  line-height: 19px;
}

.errorLabel {
  font-size: fontSize(small);
  margin-top: 5px;
  color: #fd5252;
}

.caretDownIcon {
  font-size: fontSize(smaller);
  margin-right: 12px;
}
