@import 'src/styles/functions/_layout.scss';
@import 'src/styles/mixins/_link.scss';
@import 'src/styles/_global.scss';
@import 'src/styles/mixins/_card.scss';
@import 'src/styles/mixins/_button.scss';

.root {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.nameContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.nameRow {
  flex-basis: 48%;
  max-width: 48%;
}

.card {
  @include base-card;
  padding: spacing(bigger);
  min-width: 560px;
  margin-bottom: spacing(standard);
  position: relative;
}

.backLink {
  @include base-link($primary);
  margin-bottom: spacing(larger);
}
.submitButton {
  @include base-button($primary);
  padding: 8px 12px;
  height: auto !important;
  width: 170px;
}
.submitButtonContainer {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.backLinkContainer {
  display: flex;
  justify-content: flex-start;
  font-size: fontSize(smaller);
  line-height: 19px;
}

.backIcon {
  color: $primary;
  margin-right: 7px;
  margin-top: 2px;
}

.divider {
  box-sizing: border-box;
  height: 2px;
  width: 100%;
  border: 1px solid #000000;
  opacity: 0.1;
  margin: spacing(large) 0;
}

.tosMessage {
  font-size: fontSize(small);
  line-height: 29px;
  text-align: center;
  color: #6c7a89;
}

.tosLink {
  @include base-link($primary);
}
