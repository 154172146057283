.root {
  display: flex;
  width: 100%;
  flex-direction: column;
  height: 100%;
}

.noResultsRoot {
  min-height: 100vh;
}
