@import 'src/styles/functions/_layout.scss';
@import 'src/styles/_global.scss';

.root {
  margin-bottom: spacing(standard);
}
.title {
  color: $text-primary;
  font-size: fontSize(bigger);
  line-height: 29px;
  text-align: center;
}

.subTitle {
  color: $text-primary;
  font-size: fontSize(larger);
  line-height: 29px;
  text-align: center;
}
