@import 'src/styles/_global.scss';
@import 'src/styles/functions/_layout.scss';
@import 'src/styles/mixins/_button.scss';

.root {
  width: 100%;

  :global(label.required) {
    font-weight: bold;
  }
}

.contentContainer {
  display: flex;
  align-items: flex-start;
}

.accordionsWrapper {
  width: 75%;
  margin-right: 22px;
}

.listingInformationGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  & div:nth-last-child(-n + 3) {
    margin-bottom: 0;
  }
}

.divider {
  margin: 32px auto !important;
}

.accordionSubtitle {
  color: $text-primary;
  font-size: 14px;
  line-height: 19px;
  margin-bottom: 24px;
}

.accordionList {
  columns: 2;
  list-style-type: none;
  padding-left: 0;
}

.ctaCard {
  width: 100%;
  max-width: 300px;
  padding: 0;
}

.ctaCardHeader {
  padding: 1rem 0 0 1rem;
}

.ctaCardHeaderTitle {
  margin-bottom: 0;
}

.ctaCardList {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
  font-size: 14px;
  line-height: 29px;
  color: $text-primary;
}

.ctaDivider {
  margin: 1rem 0 !important;
}

.ctaBtnWrapper {
  display: flex;
  justify-content: center;
  padding: 1rem;
  padding-top: 0;

  & a {
    color: $white;
    text-decoration: none;
    flex: 1;
  }
}

.ctaBtn {
  @include base-button($primary);
  width: 100%;
}

.boldText {
  font-weight: bold;
}
