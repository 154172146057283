@import 'src/styles/functions/_layout.scss';
@import 'src/styles/_global.scss';

.root {
  display: flex;
  flex-flow: column;
  width: 100%;
}

.imageRow {
  width: 79.9px;
  height: auto;
}

.rowBrandModelLabel {
  color: $text-primary;
  font-size: fontSize(smaller);
  font-weight: bold;
  line-height: 19px;
}

.rowEngineNameLabel {
  color: $text-primary;
  font-size: fontSize(smaller);
  line-height: 19px;
}

.rowGowagoId {
  color: #adb5bd;
  font-size: fontSize(small);
  line-height: 16px;
}
