@import 'src/styles/mixins/_header.scss';
@import 'src/styles/_global.scss';
@import 'src/styles/functions/_layout.scss';

.root {
  margin-top: spacing(big);
}

.title {
  @include title;
}
