@import 'src/styles/functions/_layout.scss';
@import 'src/styles/_global.scss';

.root {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;

  &.disabled {
    cursor: not-allowed;
  }
}

.checkbox {
  position: relative;
  height: 16px;
  width: 16px;
  border: 1px solid #adb5bd;
  border-radius: 3px;
  background-color: #f8f9fa;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 150ms cubic-bezier(0.45, 0.05, 0.55, 0.95);
  &.checked {
    border: 1px solid $primary;
    background-color: $primary;
    color: $white;
  }
  &.disabled {
    color: $light-gray;
    background-color: #e9ecef;
    border-color: #ced4da;
    cursor: not-allowed;
  }
}

.checkIcon {
  height: 10px;
  width: 10px;
  color: $white;
  font-size: 10px;
  line-height: 10px;
}

.nativeCheckbox {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.primaryLabel {
  color: $text-primary;
  font-size: fontSize(smaller);
  line-height: 19px;
  margin-left: 8px;
  margin-right: 8px;
}

.facetLabel {
  color: #adb5bd;
  font-size: fontSize(smaller);
  line-height: 19px;
}
