@import 'src/styles/functions/_layout.scss';
@import 'src/styles/_global.scss';
@import 'src/styles/mixins/_filterPanel.scss';

.root {
  margin: 0;
  width: 100%;
}

.container {
  @include filter-panel-container;
}

.title {
  @include filter-panel-title;
}

.subTitle {
  @include filter-panel-subtitle;
}

.divider {
  @include filter-panel-divider;
}

.checkboxContainer {
  @include filter-panel-checkbox-container;
}

.closedUserGroupsInputContainer {
  padding-bottom: spacing(standard);
}
