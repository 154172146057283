@import 'src/styles/functions/_layout.scss';
@import 'src/styles/mixins/_link.scss';
@import 'src/styles/_global.scss';

.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: spacing(standard);
}

.pageCountlabel {
  color: $text-primary;
  font-size: fontSize(smaller);
  line-height: 19px;
}

.page-item {
  &:first-child .page-link {
    margin-left: 0;
    border-radius: 5px 0 0 5px;
    width: auto;
    padding: 0 10px;
  }
  &:last-child .page-link {
    border-radius: 0 5px 5px 0;
    width: auto;
    padding: 0 10px;
  }
  &.active .page-link {
    z-index: 2;
    background-color: $primary;
    color: $white;
    pointer-events: none;
    cursor: default;
  }
}

.page-link {
  font-size: fontSize(smaller);
  position: relative;
  display: block;
  padding: 0;
  margin-left: -1px;
  line-height: 35px;
  width: 35px;
  text-align: center;
  color: $light-gray;
  background-color: $white;
  border: 1px solid $lighter-gray;
  text-decoration: none;
  &:hover &:focus {
    color: #188fbb;
    text-decoration: none;
    background-color: #f8f8f8;
    border-color: #d2d2d2;
  }
}

.disabled .page-link,
.page-link.disabled {
  color: #d2d2d2;
  pointer-events: none;
  background-color: #f8f8f8;
  cursor: default;
}

.pager {
  display: table;
  list-style: none;
  padding: 0;
  font-size: fontSize(small);
}

.pager .page-link {
  display: block;
  width: auto;
  white-space: nowrap;
  padding: 0 10px;
  float: left;

  &:first-child {
    border-radius: 5px 0 0 5px;
  }
  &:last-child {
    border-radius: 0 5px 5px 0;
  }
}
.pager span,
.pager .material-icons {
  display: block;
  float: left;
}

.pagination {
  display: flex;
  padding: 0;
  text-align: center;
  list-style: none;
}
