.mainContainer {
  display: flex;
  justify-content: space-between;
}

.formContainer {
  width: 75%;
  margin-right: 24px;
}

.submitContainer {
  width: 100%;
  max-width: 267px;
}
