.mainContainer {
  display: flex;
  justify-content: space-between;
}

.formContainer {
  width: 100%;
  position: relative;
  margin-bottom: 30px;
}

.submitContainer {
  width: 100%;
  max-width: 267px;
}
