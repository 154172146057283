@import 'src/styles/_global.scss';
@import 'src/styles/mixins/_header.scss';

.root {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: 35px;
  padding-bottom: 35px;
}

.titleContainer {
  padding: 0;
}

.title {
  @include title;
}

.listingsResultLabel {
  @include sub-title();
}
