@import 'src/styles/functions/_layout.scss';
@import 'src/styles/_global.scss';
@import 'src/styles/mixins/_link.scss';
@import 'src/styles/mixins/_button.scss';

.root {
  width: 100%;
  min-height: 1067px;
}

.imageRow {
  width: 79.9px;
  height: auto;
}

.rowBrandModelLabel {
  color: $text-primary;
  font-size: fontSize(smaller);
  font-weight: bold;
  line-height: 19px;
}

.rowEngineNameLabel {
  color: $text-primary;
  font-size: fontSize(smaller);
  line-height: 19px;
}

.rowGowagoId {
  color: #adb5bd;
  font-size: fontSize(small);
  line-height: 16px;
}

.itemLinkBtn {
  @include base-link(#6c757d);
}

.itemLinkContainer {
  @include outline-button($lighter-gray);
  height: 19px;
  padding-top: 5px;
  padding-bottom: 5px;
  min-height: 30px;
  min-width: 50px;
  color: #6c757d;
  font-size: fontSize(smaller);
  line-height: 19px;
  text-align: center;
}
