@import 'src/styles/functions/_layout.scss';
@import 'src/styles/mixins/_link.scss';
@import 'src/styles/_global.scss';
.root {
  margin-bottom: 0;
  list-style: none;
  background: $white;
  @include base-link($light-gray);
  &:hover {
    text-decoration: none;
    color: #212529;
    background: #e9ecef;
  }
  display: block;
  padding: 10px 0.75rem;
  color: $light-gray;
  background: transparent;
  border-radius: 0.2rem;
  -webkit-transition: background 0.1s ease-in-out;
  transition: background 0.1s ease-in-out;
  position: relative;
  text-decoration: none;
  cursor: pointer;
  font-size: fontSize(smaller);
  line-height: 19px;
}

.titleContainer {
  &:hover {
  }
}

.activeLink {
  color: $dark-navy;
  background: #e9ecef;
  & .title {
    color: $dark-navy;
  }
}

.titleIcon {
  color: $dark-gray-1;
  margin-right: 12px !important;
  vertical-align: middle !important;
  margin-top: -3px;
}

.title {
  color: $light-gray;
  font-weight: 400;
  cursor: pointer;
  &:hover {
    font-weight: 500;
  }
}

.titleAccordionIcon {
  overflow: hidden;
  transition-duration: 0.3s;
  transition-property: transform;
  margin-left: auto;
  float: right;
  margin-top: 3px;
  &.iconActive {
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
  }
}

.itemContainer {
  height: 0;
  overflow: hidden;
  transition: height 0.3s ease-in-out;
  will-change: height;
  &.active {
    height: 100%;
  }
}
