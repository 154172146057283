@mixin base-link($color) {
  &:visited {
    color: $color;
  }
  &:link {
    color: $color;
  }
  &:visited {
    color: $color;
  }
  &:hover {
    color: $color;
    text-decoration: underline;
  }
  &:active {
    color: $color;
  }
  color: $color;
  text-decoration: none;
}
