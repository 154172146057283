@import 'src/styles/functions/_layout.scss';

.descriptionTitle {
  font-weight: 600;
}

.descriptionContent {
  margin-top: spacing(small);
}

.descriptionLine {
  margin-right: spacing(standard);
}

.descriptionRow {
  display: flex;
}
