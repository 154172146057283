@import 'src/styles/functions/_layout.scss';
@import 'src/styles/_global.scss';
@import 'src/styles/mixins/_button.scss';

.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 23px;
  margin-bottom: spacing(standard);
}
.resultLabel {
  height: 19px;
  color: $white;
  font-size: fontSize(smaller);
  line-height: 19px;
  margin-right: 5px;
}

.actionButton {
  @include action-button-header-navigation;
}

.deleteButton {
  margin-right: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.navButton {
  height: 31px;
  width: 31px;
  border-radius: 3px;
  background-color: rgba(255, 255, 255, 0.3);
  color: $white;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;

  margin-right: 5px;
  &:disabled {
    background-color: darken(rgba(255, 255, 255, 0.3), 50%);
    cursor: not-allowed;
  }
}
.navIcon {
  height: 14px;
  width: 24.3px;
  color: $white;
  font-size: fontSize(smaller);
  line-height: 14px;
  text-align: center;
}
.buttonIcon {
  height: 14px;
  width: 24.3px;
  color: $white;
  font-size: fontSize(smaller);
  line-height: 14px;
  text-align: center;
  margin-right: 5px;
}

.rightContent {
  display: flex;
  align-items: center;
}

.duplicateButton {
  margin-left: 10px;
  margin-right: 10px;
  padding-left: 10px;
  padding-right: 10px;
}
