@import 'src/styles/functions/_layout.scss';
@import 'src/styles/mixins/_button.scss';
@import 'src/styles/_global.scss';

.residualValuesRow {
  border-bottom: 1px solid $lighter-gray;
  padding-bottom: 9px;
  padding-top: 9px;
  padding-left: 12px;
  display: flex;
  justify-content: flex-start;
  width: 100%;
  &:last-child {
    border: none;
  }
}

.rowValue {
  max-width: 25%;
  flex-basis: 25%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.residualValueInputRow {
  max-width: 51%;
  flex-basis: 51%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.deleteButton {
  @include clear-button;
  margin-right: 12px;
  color: $text-secondary;
  opacity: 0.5;
  font-size: fontSize(smaller);
  cursor: default;

  &:disabled {
    cursor: not-allowed;
  }

  &:not([disabled]):hover {
    color: #e53934;
    cursor: pointer;
  }
}

.equalSeparator {
  padding-left: 10px;
  padding-right: 10px;
  font-size: fontSize(smaller);
}

.residualInputPercent {
  max-width: 100px;
}

.residualInputAbsolute {
  max-width: 130px;
}

.milleage {
  font-size: fontSize(smaller);
}

.errorLabel {
  font-size: fontSize(small);
  margin-top: 5px;
  color: #fd5252;
  margin-left: 1rem;
}

.selectionRoot {
  margin: 0px;
  width: 110px;
}
