@import 'src/styles/functions/_layout.scss';
@import 'src/styles/_global.scss';

.root {
  .step {
    color: $white;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 14px;
    opacity: 0.7;
    padding: 0 50 0 0;
    text-align: left;
    margin-left: 32px;
    margin-bottom: 20px;

    &:first-child {
      margin-left: 0;
    }

    :hover {
      font-weight: bold;
      opacity: 1;
    }
  }

  .stepActive {
    font-weight: bold;
    opacity: 1;
    cursor: initial;
  }

  .stepDisabled {
    cursor: not-allowed;
  }

  .stepIcon {
    margin-right: 5px;
  }
}
