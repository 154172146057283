@import 'src/styles/_global.scss';
@import 'src/styles/mixins/_button.scss';

$border: #d2d2d2;
$header-background: #f7f7f7;

.callout {
  background: black;
  padding: 0;
  border: 1px solid $border !important;
  opacity: 1 !important;
  max-width: 244px;

  &::before {
    border-top: 8px solid $border !important;
  }

  .calloutHeader {
    background-color: $header-background;
    color: #21424f;
    font-weight: bold;
    font-size: fontSize(smaller);
    padding-left: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .calloutCloseButton {
    @include base-button($header-background);
    color: #21424f;
    border-radius: 0;
    font-size: fontSize(smaller);
    width: 42px;
  }

  .calloutBody {
    padding: 12px 9px 12.89px 12px;
  }
}
