@import 'src/styles/functions/_layout.scss';
@import 'src/styles/_global.scss';

.root {
  position: relative;
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-align: center;

  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  padding: 24px 22px 19px 24px;
  height: 64px;
}

.root.expand {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-flow: row nowrap;
  -webkit-box-pack: start;
  justify-content: flex-start;
}

.iconButton {
  background: none;
  color: $white;
  opacity: 75%;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  width: 19px;
  height: 21px;
}
.iconButton:focus {
  outline: none !important;
}
.iconButtonIcon {
  font-size: 1.5rem;
}

.signOutContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  color: $white;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  margin-left: 28px;
}
.signOutLabel {
  height: 19px;
  width: auto;
  color: $white;
  font-size: fontSize(smaller);
  font-weight: 600;
  line-height: 19px;
  margin-right: 10px;
}

.rightContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
