@import 'src/styles/mixins/_link.scss';
@import 'src/styles/_global.scss';
.root {
  width: 100%;
  min-height: 100vh;
  min-width: 0;
  -webkit-transition: margin-left 0.3s ease-in-out, left 0.3s ease-in-out, margin-right 0.3s ease-in-out,
    right 0.3s ease-in-out;
  transition: margin-left 0.3s ease-in-out, left 0.3s ease-in-out, margin-right 0.3s ease-in-out, right 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
}

.content {
  padding: 50px 34px 0 36px;
  flex: 1 0 auto;
  min-height: auto;
}

.footer {
  padding: 10px 36px 16px 36px;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  line-height: 19px;
}

.leftContent {
}

.rightContent {
}

.link {
  @include base-link($light-gray);
  margin-left: 15px;
}
