@import 'src/styles/functions/_layout.scss';
@import 'src/styles/mixins/_link.scss';
@import 'src/styles/_global.scss';
@import 'src/styles/mixins/_card.scss';

.root {
  width: 100%;
}

.formContainer {
  @include base-card;
  flex: 1;
  padding: spacing(larger) spacing(larger) spacing(large) spacing(larger);
  width: 100%;
}

.formTitle {
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  margin-bottom: 30px;
}

.avatar {
  height: 128px;
  width: 128px;
}

.formAvatarContainer {
  height: 128px;
  width: 128px;
  margin-left: auto;
  margin-right: auto;
}

.submitButtonContainer {
  margin-top: 20px;
}

.submitButton {
  padding: 8px 12px;
  height: auto !important;
}
.forgotPasswordLink {
  @include base-link($primary);
  font-size: fontSize(small);
  line-height: 16px;
  margin-bottom: spacing(larger);
  height: 40px;
  width: 140px;
}

.formContent {
  max-width: 260px;
}

.errorCard {
  border-radius: 3px;
  background-color: #e53934;
  color: $white;
  font-size: fontSize(smaller);
  line-height: 19px;
  padding: spacing(standard);
  margin: spacing(larger) 0;
}

.recoverPasswordLink {
  @include base-link($white);
  text-decoration: underline;
}

.newPasswordErrorLabelContainer {
}
.newPasswordErrorLabelContent {
  margin-top: 8px;
}

.newPasswordErrorIcon {
  font-size: 14px;
  color: #495057;
  margin-right: 5px;
  &.error {
    color: #e53934;
  }
  &.good {
    color: #4caf4f;
  }
}
.newPasswordErrorLabel {
  font-size: 14px;
  color: #495057;
  line-height: 21px;
  &.error {
    color: #e53934;
  }
}

.messageCard {
  margin-bottom: 10px;
}

.fieldContainer {
  min-height: 90px;
}
