@import 'src/styles/functions/_layout.scss';
@import 'src/styles/_global.scss';

.title {
  margin-bottom: spacing(large);
  color: $text-primary;
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
}

.form {
  display: flex;
  flex-flow: column nowrap;
}

.formRow {
  display: flex;
  flex: 1;
}

.formControl {
  margin-right: spacing(standard);
  width: 260px;
}

.label {
  font-weight: bold;
}

.text {
  line-height: 21px;
  font-size: fontSize(smaller);

  &:not(:first-of-type) {
    margin-top: 0.5rem;
  }
}

.vendorContainer {
  margin-top: spacing(large);
}

.vendorTitle {
  font-size: fontSize(smaller);
  font-weight: bold;
  line-height: 19px;
}

.divider {
  width: 100%;
  border: 1px solid #000000;
  opacity: 0.1;
  margin-top: spacing(small);
}

.dividerVendor {
  box-sizing: border-box;
  border: 1px solid $light-gray;
  width: 100%;
  height: 2px;
  opacity: 0.1;
}

.vendor {
  padding: 11px 12px 13px 12px;
  font-size: 14px;
  line-height: 19px;
}
