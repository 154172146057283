@import 'src/styles/functions/_layout.scss';
@import 'src/styles/mixins/_button.scss';
@import 'src/styles/_global.scss';

.root {
}

.headerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: spacing(larger);
}

.headerLabel {
  font-size: fontSize(large);
  line-height: 27px;
}

.columnHeaderContainer {
  border-bottom: 2px solid $lighter-gray;
  display: flex;
  font-size: fontSize(smaller);
  line-height: 19px;
  padding-bottom: 8px;
}

.useDefaultValuesCheckboxContainer {
  margin-bottom: 36px;
}

.columnHeader {
  padding-left: 12px;
  max-width: 20%;
  flex-basis: 20%;
}

.columnResidualValue {
  padding-left: 12px;
  max-width: 40%;
  flex-basis: 40%;
}

.columnMonthlyPrice {
  display: flex;
  flex-flow: column;
}

.columnMonthlyPriceDescription {
  font-size: fontSize(small);
  line-height: 16px;
}

.addAnnualMileage {
  @include clear-button;
  background-color: #f9fafb;
  height: 52px;
  width: 100%;
  padding-top: 18px;
  padding-bottom: 18px;
  padding-left: 12px;
  font-size: fontSize(smaller);
  line-height: 19px;
  display: flex;
  justify-content: flex-start;
  cursor: default;

  &:disabled {
    cursor: not-allowed;
  }

  &:not([disabled]) {
    cursor: pointer;
  }
}

.plusSquareIcon {
  height: 16px;
  width: 14px;
  opacity: 0.5;
  color: $text-primary;
  font-size: fontSize(standard);
  line-height: 16px;
  margin-right: 9px;
}
