.root {
  width: 100%;
  position: relative;
}

.listContainer {
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
  list-style: none;
  padding-inline-start: 0px;
}

.listItem {
  height: 25px;
}
