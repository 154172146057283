@import 'src/styles/functions/_layout.scss';
@import 'src/styles/mixins/_link.scss';
@import 'src/styles/_global.scss';

.root {
  text-align: center;
  font-size: fontSize(smaller);
  line-height: 29px;
}

.contactLink {
  @include base-link($primary);
}
