@import 'src/styles/functions/_layout.scss';
@import 'src/styles/_global.scss';

.root {
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  color: $white;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  &.dark {
    color: $light-gray;
  }
}

.root:hover .buttonIcon {
  opacity: 1;
}

.languageLabel {
  font-size: fontSize(smaller);
  line-height: 19px;
}
.buttonIcon {
  font-size: 14px;
  margin-left: 10px;
}

.popoverContainer {
  padding-bottom: 5px;
  width: 120px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  background-color: $white;
  margin-top: 3px;
  z-index: 100;
}

.popoverMenuButton {
  color: $text-secondary;
  width: 100%;
  font-size: fontSize(smaller);
  border: none;
  cursor: pointer;
  outline: inherit;
  padding-left: 24px;
  padding-bottom: 6px;
  padding-top: 7px;
  text-align: left;
  &:hover {
    background-color: #dedede;
  }
  &.active {
    background-color: $primary;
    color: $white;
  }
}
