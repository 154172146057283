@import 'src/styles/functions/_layout.scss';
@import 'src/styles/_global.scss';

.root {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 10px;
}

.circleOuter {
  height: 16px;
  width: 16px;
  border: 1px solid #adb5bd;
  background-color: #f8f9fa;
  border-radius: 50%;
  &.selected {
    background-color: $primary;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
  }
}

.circleInner {
  height: 6px;
  width: 6px;
  background-color: $white;
  border-radius: 50%;
}
.label {
  color: $text-primary;
  font-size: fontSize(smaller);
  line-height: 19px;
  padding-left: 8px;
}
