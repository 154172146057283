@import 'src/styles/_global.scss';
@import 'src/styles/functions/_layout.scss';

.header {
  font-size: fontSize(large);
  line-height: 27px;
  margin-bottom: spacing(larger);
}

.row {
  display: flex;
  justify-content: space-between;
}

.column {
  max-width: 32%;
  flex-basis: 32%;
}
