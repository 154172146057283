@import 'src/styles/functions/_layout.scss';
@import 'src/styles/_global.scss';

.inputAdornment {
  height: 100%;
  width: 36px;
  background-color: #e9ecef;
  display: flex;
  align-items: center;
  justify-content: center;
  &.start {
    margin-right: 10px;
    border-right: 1px solid #ced4da;
  }
  &.isText {
    width: 50px;
  }
}

.adormentIcon {
  height: 14px;
  width: 14px;
  color: $text-secondary;
  font-size: fontSize(smaller);
  line-height: 14px;
}

.root {
  display: block;
  width: 100%;
  height: 37px;
  padding-left: 10px;
  padding-top: 8px;
  padding-bottom: 8px;
  line-height: 1.5;
  color: $text-secondary;
  background-color: $white;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.2rem;
  -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  overflow: visible;
  margin: 0;
  &.active {
    border: 1px solid #2196f3;
  }
  &.disabled {
    background-color: #e9ecef;
    cursor: not-allowed;
  }
  &.error {
    border: 1px solid #e53934;
  }
  &.withInputAdornment {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    overflow: hidden;
  }
}

.input {
  width: 100%;
  height: 100%;
  border: none;
  font-size: fontSize(smaller);
  &:focus {
    outline: none;
  }
  &:disabled {
    color: $text-secondary;
    background-color: #e9ecef;
    cursor: not-allowed;
  }
}
