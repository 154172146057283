@import 'src/styles/functions/_layout.scss';
@import 'src/styles/mixins/_link.scss';
@import 'src/styles/_global.scss';

.root {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;

  max-width: 560px;
}

.avatar {
  height: 128px;
  width: 128px;
}

.googleLoginButtonCard {
  margin-top: 1rem;
  margin-bottom: 2rem;
  display: flex;
  justify-content: center;
}

.googleLoginButton {
  width: 100%;
}
