@import 'src/styles/_global.scss';
@import 'src/styles/functions/_layout.scss';
@import 'src/styles/mixins/_link.scss';
@import 'src/styles/mixins/_divider.scss';

.root {
  width: 100%;
  position: relative;
}

.formColumn {
  display: flex;
  justify-content: space-between;
}

.formRow {
  max-width: 32%;
  flex-basis: 32%;
}
.divider {
  @include divider;
}

.leaseTypeLabel {
  font-size: fontSize(smaller);
  line-height: 19px;
}

.leaseTypeContainer {
  margin-top: 17px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.headerLabel {
  font-size: fontSize(large);
  line-height: 27px;
  margin-bottom: spacing(larger);
}

.defaultOwnershipContainer {
  margin-bottom: spacing(larger);
  display: flex;
}

.infoIconContainer {
  cursor: pointer;
}

.infoIcon {
  color: #00a3f8;
  font-size: fontSize(smaller);
}

.listingSettingsLink {
  @include base-link($primary);
  margin-left: 0.25em;
}
