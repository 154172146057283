@import 'src/styles/functions/_layout.scss';
@import 'src/styles/mixins/_card.scss';

.root {
  margin-right: 24px;
  min-width: 267px;
  font-size: 14px;
}

.rootCard {
  @include base-card;
}

.navItem {
  padding: 19.38px spacing(standard) 17.62px spacing(standard);
  cursor: pointer;
  display: block;
  text-decoration: none;
  color: #495057;

  &:first-of-type {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }

  &:last-of-type {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  &:hover {
    background-color: #e9ecef;
  }
}

.navItemSelected {
  background-color: $primary;
  color: $white;

  &:hover {
    background-color: darken($primary, 5%);
  }
}
