@import 'src/styles/_global.scss';
@import 'src/styles/mixins/_link.scss';
@import 'src/styles/functions/_layout.scss';

.root {
  position: relative;
}

.content {
  display: flex;
  align-items: center;
}

.checkboxContainer {
  min-width: 295px;
}

.text {
  font-size: fontSize(smaller);
  line-height: 19px;
}

.yourLeasingLink {
  @include base-link($primary);
  margin-left: 0.25rem;
}

.lastSync {
  color: $text-secondary;
  margin-top: spacing(small);
}

.hidden {
  visibility: hidden;
}
