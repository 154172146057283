@import 'src/styles/functions/_layout.scss';
@import 'src/styles/mixins/_divider.scss';
@import 'src/styles/mixins/_button.scss';
@import 'src/styles/_global.scss';

.title {
  font-size: fontSize(large);
  line-height: 27px;
  margin-bottom: spacing(larger);
}

.rowTitle {
  font-size: fontSize(smaller);
  font-weight: bold;
  line-height: 24px;
}

.divider {
  @include divider;
}

.copyButton {
  @include base-button($primary);
  padding: 5px;
  display: block;
  margin-left: auto;
  margin-bottom: 1rem;
}

.copyIcon {
  margin-right: spacing(small);
}
