@import 'src/styles/functions/_layout.scss';

.checkboxRow {
  margin-bottom: 1rem;
}

.row {
  display: flex;
  justify-content: space-between;
}

.column {
  max-width: 32%;
  flex-basis: 32%;
}

.guaranteeLabel {
  margin-bottom: spacing(standard);
  font-size: fontSize(smaller);
  line-height: 19px;
}

.fromDatePickerContainer {
  margin-left: 23px;
  max-width: 124px;
}
