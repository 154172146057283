.root {
  padding: 0;
  height: 100%;
}

.resultsContainer {
  display: flex;
}
.filterContainer {
  margin-right: 24px;
  max-width: 267px;
  width: 100%;
}
