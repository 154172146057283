@import 'src/styles/_global.scss';
@import 'src/styles/functions/_layout.scss';
@import 'src/styles/mixins/_button.scss';

.root {
  display: flex;
  align-items: center;
  border-bottom: 1px solid $lighter-gray;
  padding: 12px 16px 12px 12px;
  font-size: fontSize(smaller);
}

.sellerInfo {
  display: flex;
}

.avatar {
  width: 40px;
}

.nameAndEmail {
  display: flex;
  flex-flow: column;
  padding-left: 16px;
  min-width: 350px;
  margin-right: 31px;
}

.name {
  font-size: fontSize(smaller);
  line-height: 19px;
  font-weight: bold;
}

.statusContainer {
  display: flex;
  align-items: center;
}

.status {
  margin-left: 8px;
}

.statusIcon {
  font-size: fontSize(standard);
  line-height: 16px;
}

.activeIcon {
  color: #4caf4f;
}

.inactiveIcon {
  color: #ced4da;
}

.pendingIcon {
  color: #ff8000;
}

.button {
  margin-left: auto;
  display: flex;
}

.sendInviteButton {
  @include base-button($primary);
  font-size: fontSize(smaller);
  padding: 4px 10px;
}

.resendInviteButton {
  @include outline-button($primary);
  font-size: fontSize(smaller);
  padding: 4px 10px;
}

.buttonIcon {
  margin-right: 9px;
}
