@import 'src/styles/_global.scss';
@import 'src/styles/mixins/_button.scss';
@import 'src/styles/functions/_layout.scss';

.root {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.submitButton {
  @include base-button($primary);
  padding: 8px 12px;
  height: auto !important;
  margin-top: spacing(large);
}
