@import 'src/styles/_global.scss';
@import 'src/styles/functions/_layout.scss';

.root {
  border-radius: 3px;
  background: #e53934;
  color: $white;
  font-size: fontSize(smaller);
  padding: spacing(standard) spacing(large);
}

.title {
  font-weight: bold;
  line-height: 19px;
}

.childrenContainer {
  margin-top: spacing(small);
}
