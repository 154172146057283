@import 'src/styles/functions/_layout.scss';
@import 'src/styles/_global.scss';

@mixin title {
  height: 34px;
  color: $white;
  font-size: fontSize(big);
  font-weight: 600;
  line-height: 34px;
}

@mixin sub-title {
  color: $lighter-gray;
  font-size: fontSize(smaller);
  line-height: 19px;
}
