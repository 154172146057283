@import 'src/styles/_global.scss';
@import 'src/styles/mixins/_header.scss';

.root {
  padding: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.titleContainer {
  padding: 0;
}

.title {
  @include title;
}

.listingsResultLabel {
  @include sub-title;
}
