@import 'src/styles/functions/_layout.scss';
@import 'src/styles/_global.scss';

.root {
  margin-bottom: 1rem;
}

.label {
  display: inline-block;
  margin-bottom: 0.5rem;
  box-sizing: border-box;
  cursor: default;
  color: $text-primary;
  font-size: fontSize(smaller);
  line-height: 19px;
}

.errorLabel {
  font-size: fontSize(small);
  margin-top: 5px;
  color: #fd5252;
}

.selectionContainer {
  display: flex;
}

.monthSelection {
  min-width: 90px;
  margin-right: 10px;
  font-size: 16px;
}
.yearSelection {
  min-width: 160px;
  font-size: 16px;
}

.required {
  font-weight: bold;
}

.yearOnly {
  width: 100%;
}
