@import 'src/styles/functions/_layout.scss';
@import 'src/styles/_global.scss';

@mixin base-button($color) {
  background-color: $color;
  color: $white;
  border: none;
  padding: 10px;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  font-size: fontSize(standard);
  line-height: 22px;
  text-align: center;
  border-radius: 5px;
  &:active {
    background-color: darken($color, 5%);
  }
  &:hover {
    background-color: darken($color, 5%);
  }

  &:disabled {
    cursor: not-allowed;
    background: lightgray;
  }
}

@mixin outline-button($color) {
  color: $color;
  border: 1px solid $color;
  padding: 10px;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  font-size: fontSize(standard);
  line-height: 22px;
  text-align: center;
  border-radius: 5px;
  background: white;

  &:active {
    background-color: darken(white, 5%);
  }
  &:hover {
    background-color: darken(white, 5%);
  }

  &:disabled {
    background-color: darken(white, 5%);
    cursor: not-allowed;
  }
}

@mixin clear-button {
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  /* show a hand cursor on hover; some argue that we
  should keep the default arrow cursor for buttons */
  cursor: pointer;
}

@mixin full-width-add-button {
  @include clear-button;
  background-color: #f9fafb;
  height: 52px;
  width: 100%;
  padding-top: 18px;
  padding-bottom: 18px;
  padding-left: 12px;
  font-size: fontSize(smaller);
  line-height: 19px;
  display: flex;
  justify-content: flex-start;
  cursor: default;

  &:disabled {
    cursor: not-allowed;
  }

  &:not([disabled]) {
    cursor: pointer;
  }
}

@mixin full-width-add-button-icon {
  height: 16px;
  width: 14px;
  opacity: 0.5;
  color: $text-primary;
  font-size: fontSize(standard);
  line-height: 16px;
  margin-right: 9px;
}

@mixin delete-icon-button {
  @include clear-button;
  margin-right: 12px;
  color: $text-secondary;
  opacity: 0.5;
  font-size: fontSize(smaller);
  cursor: default;

  &:disabled {
    cursor: not-allowed;
  }

  &:not([disabled]):hover {
    color: #e53934;
    cursor: pointer;
  }
}

@mixin action-button-header-navigation {
  @include base-button($dark-gray);
  font-size: 14px;
  line-height: 19px;
  height: 31px;
  min-width: 132px;
  padding: 0;
}
