@import 'src/styles/functions/_layout.scss';
@import 'src/styles/_global.scss';
@import 'src/styles/mixins/_button.scss';

.root {
  display: flex;
  align-items: center;
}

.resultLabel {
  height: 19px;
  color: $white;
  font-size: fontSize(smaller);
  font-weight: 600;
  line-height: 19px;
  margin-right: 10px;
}

.navButton {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 31px;
  width: 31px;
  border-radius: 3px;
  background-color: rgba(255, 255, 255, 0.3);
  color: $white;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;

  margin-right: 5px;
  &:disabled {
    background-color: darken(rgba(255, 255, 255, 0.3), 50%);
    cursor: not-allowed;
  }
}
.navIcon {
  height: 14px;
  width: 7px;
  color: $white;
  font-size: fontSize(smaller);
  line-height: 14px;
  text-align: center;
}
