@import 'src/styles/_global.scss';
@import 'src/styles/functions/_layout.scss';
@import 'src/styles/mixins/_link.scss';

.title {
  margin-bottom: 9px;
  font-size: fontSize(smaller);
  line-height: 19px;
}

.formColumn {
  display: flex;
  justify-content: space-between;
}

.formRow {
  max-width: 32%;
  flex-basis: 32%;
}

.infoIconContainer {
  cursor: pointer;
}

.infoIcon {
  color: #00a3f8;
  font-size: fontSize(smaller);
}

.defaultVendorLocation {
  margin-bottom: spacing(larger);
  display: flex;
}

.vendorInformationLink {
  @include base-link($primary);
  margin-left: 0.25em;
}
