@import 'src/styles/_global.scss';

.root {
  display: flex;
  width: 100%;
  border-bottom: 1px solid $lighter-gray;
}
.rowSelected {
  background: $row-selected;
}

.selectionContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: relative;
}

.rowLink {
  &:visited {
    color: inherit;
  }
  &:link {
    color: inherit;
  }
  &:visited {
    color: inherit;
  }
  &:hover {
    color: inherit;
    background: $row-hover;
  }
  &:active {
    color: inherit;
  }
  color: inherit;
  text-decoration: none;
}

.selectable {
  cursor: pointer;

  &:hover {
    background: #eee;
  }
}
