@import 'src/styles/_global.scss';
@import 'src/styles/functions/_layout.scss';

.root {
  background: #fff;
  min-height: 100vh;
  min-width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
}

.logo {
  width: 400px;
  height: 400px;
}
