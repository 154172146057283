@import 'src/styles/_global.scss';

.root {
  width: 100%;
  height: 340px;
  background-color: $secondary-bg;

  .row {
    display: flex;
    flex-flow: row;
    height: 100%;

    .column {
      width: 100%;
      height: 100%;

      h1 {
        color: #ffffff;
        font-size: 25px;
        font-weight: 600;
        line-height: 34px;
        margin: 0;
      }

      p {
        color: #f5f8fa;
        font-size: 14px;
        line-height: 19px;
        margin: 6px 0;
      }

      img {
        margin-top: 36px;
      }
    }
  }
}

.textContainer {
  padding: 60px 0 0 36px;
}
