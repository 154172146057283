@import 'src/styles/functions/_layout.scss';
@import 'src/styles/_global.scss';

@mixin divider {
  box-sizing: border-box;
  height: 1px;
  width: 100%;
  border-bottom: 1px solid #000000;
  opacity: 0.1;
  margin: spacing(large) 0;
}

@mixin divider-vertical {
  box-sizing: border-box;
  height: 20px;
  width: 1px;
  border-left: 1px solid $lighter-gray;
  margin: 0 14.5px;
}
