@import 'src/styles/functions/_layout.scss';
@import 'src/styles/_global.scss';
@import 'src/styles/mixins/_input.scss';

.root {
  display: flex;
  flex-flow: column;
}

.formRow {
  display: flex;
  align-items: center;
}

.to {
  margin: 0 8px;
  transform: translateY(-40%);
}

.input {
  width: 50px;
}

.label {
  display: inline-block;
  margin-bottom: 0.5rem;
  box-sizing: border-box;
  cursor: default;
  color: $text-primary;
  font-size: fontSize(smaller);
  line-height: 19px;
}

.selection {
  margin-left: 8px;
  min-width: 131px;
}

.errorLabelClass {
  position: absolute;
}
