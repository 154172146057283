@import 'src/styles/mixins/_header.scss';
@import 'src/styles/_global.scss';
@import 'src/styles/functions/_layout.scss';
@import 'src/styles/mixins/_link.scss';

.header {
  margin-top: spacing(big);
}

.title {
  @include title;
}

.card {
  margin-top: spacing(big);
  padding: spacing(big) spacing(large) 38px spacing(large);
  max-width: 558px;
}

.row {
  font-size: fontSize(smaller);
  line-height: 19px;
  margin-bottom: spacing(large);
  display: flex;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.iconContainer {
  margin-right: spacing(standard);
}

.icon {
  font-size: fontSize(smaller);
  line-height: 14px;
  color: $text-secondary;
}

.link {
  @include base-link($text-primary);
}
