@import 'src/styles/functions/_layout.scss';
@import 'src/styles/mixins/_button.scss';
@import 'src/styles/_global.scss';

.root {
  display: flex;
  justify-content: flex-start;
  border-bottom: 1px solid $lighter-gray;
}

.contractLengthRow {
  padding-left: 12px;
  max-width: 25%;
  flex-basis: 25%;
  padding-bottom: 9px;
  padding-top: 9px;
  display: flex;
  align-items: flex-start;
}

.residualValuesContainer {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.deleteButton {
  @include delete-icon-button;
}

.addAnnualMileage {
  @include clear-button;
  background-color: #f9fafb;
  height: 52px;
  width: 100%;
  padding-top: 18px;
  padding-bottom: 18px;
  padding-left: 12px;
  font-size: fontSize(smaller);
  line-height: 19px;
  display: flex;
  justify-content: flex-start;
  cursor: default;

  &:disabled {
    cursor: not-allowed;
  }

  &:not([disabled]) {
    cursor: pointer;
  }
}

.plusSquareIcon {
  height: 16px;
  width: 14px;
  opacity: 0.5;
  color: $text-primary;
  font-size: fontSize(standard);
  line-height: 16px;
  margin-right: 9px;
}

.months {
  font-size: fontSize(smaller);
}
