@import 'src/styles/functions/_layout.scss';
@import 'src/styles/_global.scss';
@import 'src/styles/mixins/_divider.scss';

.title {
  margin-bottom: spacing(large);
  color: $text-primary;
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
}

.form {
  display: flex;
  flex-flow: column nowrap;
}

.formRow {
  display: flex;
  flex: 1;
}

.formControl {
  margin-right: spacing(standard);
  width: 260px;
}

.divider {
  @include divider;
}

.label {
  font-weight: bold;
}

.text {
  line-height: 21px;
  font-size: fontSize(smaller);

  &:not(:first-of-type) {
    margin-top: 0.5rem;
  }
}
