@import 'src/styles/functions/_layout.scss';
@import 'src/styles/_global.scss';

.root {
  width: 100%;
  position: relative;

  .checkbox {
    margin-bottom: spacing(larger);
  }

  .secondaryLabel {
    color: $text-primary;
    font-family: 'Nunito Sans';
    line-height: 16px;
    margin-bottom: spacing(larger);
    font-size: fontSize(small);
    width: 500px;
    margin-top: -10px;
  }

  .otherDealTypesContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;

    .otherDealType {
      max-width: 32%;
      flex-basis: 32%;
    }

    .alignNormal > div {
      align-items: normal;
    }
  }
}

.icon {
  font-size: fontSize(standard);
}

.formColumn {
  display: flex;
  justify-content: space-between;
}

.formRow {
  max-width: 32%;
  flex-basis: 32%;
}

.formLargerRow {
  max-width: 50%;
  flex-basis: 50%;
}

.divider {
  box-sizing: border-box;
  height: 1px;
  width: 100%;
  border-top: 1px solid #000000;
  opacity: 0.1;
  margin: spacing(large) 0;
}
