@import 'src/styles/_global.scss';
@import 'src/styles/functions/_layout.scss';

.root {
  min-width: 230px;
  max-width: 230px;
  transition: all 0.5s;
  -webkit-transition: all 0.25s;
  border-right: 0;
  box-shadow: 0 0 2rem 0 rgba(0, 0, 0, 0.05);
  z-index: 1;
  min-height: 100%;
}
.root.hidden {
  min-width: 0px;
  max-width: 0px;
  overflow: hidden;
}

.content {
  background: $white;
  -webkit-transition: margin-left 0.3s ease-in-out, left 0.3s ease-in-out, margin-right 0.3s ease-in-out,
    right 0.3s ease-in-out;
  transition: margin-left 0.3s ease-in-out, left 0.3s ease-in-out, margin-right 0.3s ease-in-out, right 0.3s ease-in-out;
  height: 100%;
  min-height: 100%;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  overflow-x: hidden;
}
.content.expand {
  min-width: 230px;
}

.brandContainer {
  display: flex;
  padding: 19px 39px 17px 25px;
  background: $secondary-bg;
  width: 100%;
}
.avatarContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 25px;
}

.userFullname {
  height: 19px;
  color: $dark-gray-1;
  font-size: fontSize(smaller);
  line-height: 19px;
  text-align: center;
  font-weight: 600;
  margin-top: 9px;
}

.userVendorGroup {
  height: 16px;
  color: $dark-gray-1;
  font-size: fontSize(small);
  line-height: 16px;
  text-align: center;
  margin-top: 5px;
}

.listingMenuContainer {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin: 8px;
  margin-top: 25px;
  padding-bottom: spacing(standard);
  padding-top: spacing(standard);
  border-radius: 3px;
  min-width: 214px;
}

.listingMenuContainerNormalUser {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin: 8px;
  margin-top: 25px;
  padding-bottom: spacing(standard);
  padding-top: spacing(standard);
  border-radius: 3px;
  min-width: 214px;
}

.listingBorderlessMenuContainer {
  margin: 8px;
  min-width: 214px;
}

.listingBorderlessMenuContainerMultipleVendorsTools {
  margin-top: 23px;
}

.navHeader {
  color: #adb5bd;
  padding: 2px 14px;
  font-size: fontSize(small);
  font-weight: 600;
  line-height: 16px;
  margin-top: spacing(standard);
  padding-bottom: 10px;
}

.navContainer {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-pack: start;
  justify-content: flex-start;
  -webkit-box-flex: 1;
  flex: 1;
  background: $white;
  min-width: 230px;
}

.listingHeaderTitle {
  color: $text-secondary;
  font-size: fontSize(smaller);
  font-weight: 600;
  line-height: 19px;
  padding: 0px spacing(larger);
}
