@import 'src/styles/_global.scss';
@import 'src/styles/functions/_layout.scss';

@mixin filter-panel-title {
  color: $text-secondary;
  font-size: fontSize(standard);
  line-height: 22px;
  margin-bottom: 23px;
  font-weight: 600;
}

@mixin filter-panel-container {
  box-sizing: border-box;
  min-width: 267px;
  padding: spacing(standard) spacing(standard) spacing(larger) spacing(standard);
}

@mixin filter-panel-divider {
  width: 100%;
  border-bottom: 1px solid #000000;
  opacity: 0.1;
  margin-top: spacing(larger);
  margin-bottom: 18px;
}

@mixin filter-panel-subtitle {
  color: $text-secondary;
  font-size: fontSize(smaller);
  line-height: 19px;
  margin-bottom: spacing(standard);
  font-weight: 600;
}

@mixin filter-panel-checkbox-container {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}
