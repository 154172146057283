@import 'src/styles/functions/_layout.scss';
@import 'src/styles/_global.scss';

.root {
  margin-bottom: 1rem;
  font-size: fontSize(standard);
}

.indicatorSeparator {
  display: none;
}

.label {
  display: block;
  margin-bottom: 0.5rem;
  box-sizing: border-box;
  cursor: default;
  color: $text-primary;
  font-size: fontSize(smaller);
  line-height: 19px;
}

.errorLabel {
  font-size: fontSize(small);
  margin-top: 5px;
  color: #fd5252;
}

.caretDownIcon {
  font-size: fontSize(smaller);
  margin-right: 12px;
}
