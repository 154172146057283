@import 'src/styles/functions/_layout.scss';
@import 'src/styles/mixins/_link.scss';
@import 'src/styles/_global.scss';
@import 'src/styles/mixins/_card.scss';
@import 'src/styles/mixins/_button.scss';

.root {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.card {
  @include base-card;
  padding: spacing(bigger);
  min-width: 560px;
  margin-bottom: spacing(standard);
}

.backLink {
  @include base-link($primary);
  margin-bottom: spacing(larger);
}
.submitButton {
  @include base-button($primary);
  padding: 8px 12px;
  height: auto !important;
  width: 170px;
}
.submitButtonContainer {
  display: flex;
  justify-content: center;
  margin-top: spacing(larger);
}

.backLinkContainer {
  display: flex;
  justify-content: flex-start;
  font-size: fontSize(smaller);
  line-height: 19px;
}

.backIcon {
  color: $primary;
  margin-right: 7px;
  margin-top: 2px;
}

.apiErrorLabel {
  font-size: 12px;
  margin-top: 5px;
  color: #fd5252;
  padding-top: 10px;
}
