.root {
}

.loadingWrapper {
  position: absolute;
  background-color: rgba(226, 226, 226, 0.55);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 100;
}

.loadingIcon {
  position: absolute;
  top: 36%;
  left: 50%;
  color: #2196f3;
  font-size: 3rem;
}

.loadingBar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 3px;
  z-index: 100;
}
