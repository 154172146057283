.root {
}

.content {
  margin-top: spacing(big);
  display: flex;
}

.subContent {
  width: 100%;
}
