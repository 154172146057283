@import 'src/styles/_global.scss';
@import 'src/styles/functions/_layout.scss';

.root {
  margin-bottom: 30px;
  box-shadow: 0 0 2rem 0 rgba(0, 0, 0, 0.05);
  position: relative;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: $white;
  background-clip: border-box;
  border: 0px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.2rem;
}

.header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  border-bottom: 0px solid rgba(0, 0, 0, 0.125);
  &:first-child {
    border-radius: calc(0.2rem - 0px) calc(0.2rem - 0px) 0 0;
  }
}
.title {
  font-size: fontSize(standard);
  color: $text-secondary;
  margin-bottom: 0.75rem;
}
.subTitle {
  font-weight: 400;
  color: $light-gray !important;
  margin-top: -0.375rem;
  margin-bottom: 0;
  font-size: 0.875rem;
}
.body {
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}
