@import 'src/styles/_global.scss';
@import 'src/styles/functions/_layout.scss';
@import 'src/styles/mixins/_button.scss';

.root {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(43, 52, 69, 0.8);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}

.content {
  max-width: 50%;
}

.controls {
  display: flex;
  margin-top: spacing(large);
}

.message {
  font-size: 72px;
  color: $white;
  line-height: 72px;
  letter-spacing: -2px;
  font-weight: bold;
}

.primaryButton {
  @include base-button($primary);
  background: $primary;
  cursor: pointer;
  font-size: fontSize(standard);
}

.errorButton {
  @include base-button($error);
}

.outlineButton {
  @include outline-button($white);
  background: transparent;
  cursor: pointer;
  font-size: fontSize(standard);
}

.okButtonLeft {
  margin-right: spacing(small);
}

.okButtonRight {
  margin-left: spacing(small);
}

.description {
  margin-top: 60px;
  color: $white;
  font-size: fontSize(larger);
}

.title {
  color: $white;
  margin-bottom: spacing(small);
  font-size: fontSize(larger);
  line-height: 24px;
  font-weight: 600;
}
